import React from "react";
import asset5 from "../../../assets/coc_asset5.png";
import asset6 from "../../../assets/coc_asset6.png";
import asset7 from "../../../assets/coc_asset7.png";
import "./prototype.css";

const Prototype = () => {
    return (
        <div className="container">
    <div className="section__padding">
      <h1>Prototype</h1>
    </div>
    
    <div className="coc_prototype_section section__padding">
        <div className="coc_image_prototype">
            <img src={asset5} alt="display iamge of prototype" />
        </div>
        <div className="coc_image_prototype">
            <img src={asset6} alt="display iamge of prototype" />
        </div>
        <div className="coc_image_prototype">
            <img src={asset7} alt="display iamge of prototype" />
        </div>
    </div>
    </div>
    );
  };
  
  export default Prototype;




