import React from "react";
import './requirements3.css'
import asset5 from "../../../assets/rapid_asset5.png";
import asset6 from "../../../assets/rapid_asset6.png";

const Requirements3 = () => {
  return (
    <div className="container section__padding">
    <div className="section_padding">
      <h1>REQUIREMENTS</h1>
    </div>


    <div className="athena_requirements section__padding2">
    <h2 className="athena_midwidth">Problem+</h2>
        <div>
            <div className="flex_container">
                <p>Needs Change</p>
                <div className="coc_flex1_list">
                    <ul>
                        <l1>Passengers are unable to make informed decisions about accurate travel times and following social distancing protocols using transit networks because they do not know how many people are on trains and buses during the pandemic. </l1>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>User Issues</p>
                <div className="coc_flex1_list">
                    <ul>
                        <l1>Users are forced to make decisions on their health and wellness without proper quantitive data about the transit system creating friction in their livelihoods.</l1>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Impact</p>
                <div className="coc_flex1_list">
                    <ul>
                        <l1>Users are forced into a situation that jeopardizes their health to fulfill their other requirements such as work. This puts strains on families and careers because these user goals directly conflict by forcing a user to choose between their wellness and career aspirations.</l1>
                    </ul>
                </div>
            </div>
            </div>
    </div>

    <div className="athena_requirements section__padding2">
    <h2 className="athena_midwidth">Vision+</h2>
        <div>
            <div className="flex_container">
                <p>Will Change</p>
                <div className="coc_flex1_list">
                    <ul>
                        <l1>Passengers are given up-to-data transit data allowing them to create efficient transportation maps while synthesizing that information with public health guidelines for social distance requirements.</l1>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>User Gain</p>
                <div className="coc_flex1_list">
                    <ul>
                        <l1>Users can make informed decisions about the transit network giving them more flexibility in their schedules while not sacrificing their health and safety. </l1>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Benefits</p>
                <div className="coc_flex1_list">
                    <ul>
                        <l1>Users can manage their work and social lives better by having data that will allow them to make the best decisions possible when traveling saving them time and money while protecting their health and welfare. This creates less stress and better communication by allowing users to explore and make informed decisions about using transit networks.</l1>
                    </ul>
                </div>
            </div>
            </div>
            </div>

        <h1>CONTEXT SCENARIOS</h1>
        <h2>TORY FOSTER</h2>
        <div className="rapid_image_requirements">
            <img src={asset5} alt="display iamge of prototype" />
        </div>

        <div className="coc_scenarios section__padding">
        <div className="coc_requirements_section">
            <div className="flex_container">
                <p>Key Path 1</p>
                    <ul>
                        <p class="list_text">Tory wakes from a long weekend in which she was working from home. She is well rested but eager to start the day. She opens her smartphone to turn on her favorite transit application of choice. She opens the landing page and selects her morning work route which calculates the fastest route possible and notifies her there are major transit system issues (Touchpoint 1). Nothing major, so she continues to get ready for her hectic day.</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 2</p>
                    <ul>
                        <p class="list_text">Before Tory leaves, she checks to see user capacity to see if she can follow social distancing guidelines because of the current pandemic (Touchpoint 2). She walks out the door and heads toward her bus stop as the sun is beginning to rise.</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 3</p>
                    <ul>
                        <p class="list_text">Tory opens the App to see if any transit information has been updated as she arrives at the bus stop (Touchpoint 3). The bus is exactly 2 minutes away as expected. As the bus arrives, Tory double-checks to see how much money she has on her transit pass on her smartphone before swiping it against a kiosk as payment. (Touchpoint 4) She sits down and feels a touch tired as she worked all weekend from bed. She orders a large black coffee and protein bar which will be ready in time for her to pick up at her transfer (Touchpoint 5).</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 4</p>
                    <ul>
                        <p class="list_text">A few minutes before she arrives at the train station, a notification alerts her about a stalled subway car. She opens the application and finds an alternative route (Touchpoint 6). Tory also checks the capacity of the subway car which states it is at 40% capacity, a li le higher than she is comfortable with but acceptable. Deanna rubs hand sanitizer from her purse as she enters the transit hub. </p>
                    </ul>
            </div>
        </div>

        <div className="coc_requirements_section">
            <div className="flex_container">
                <p>Key Path 5</p>
                    <ul>
                        <p class="list_text">Tory arrives at a transfer hub. Tory walks over to the coffee shop and picks up her hot coffee and snack. It is delicious. She smiles and speaks directly into her phone saying “25 percent tip” (Touchpoint 7). Well deserved she thinks. Her smartphone dings and she walks over to the transfer subway (Touchpoint 8).</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 6</p>
                    <ul>
                        <p class="list_text">She enters the train while listening to a podcast while being aware of her surroundings. Her subway ride is about 15 minutes. A longer notification dings and she knows she is a minute away from her final destination (Touchpoint 9).</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 7</p>
                    <ul>
                        <p class="list_text">Tory gets off the train and opens the application to review her journey focusing on time saved and health measures (Touchpoint 10). Her train ride was 7 minutes longer than expected, however, not bad considering a stalled train was causing stress on the system. The app also lets her know that the subway station is getting about 65% capacity and social distancing will be difficult. She closes the application which then downloads today's data. She quickly exits the station and arrives at work ready to accomplish the day.</p>
                    </ul>
            </div>
            
        </div>
        </div>

        <h2>LEOBIEN CONOY</h2>
        <div className="rapid_image_requirements">
            <img src={asset6} alt="display iamge of prototype" />
        </div>

        <div className="coc_scenarios section__padding">
        <div className="coc_requirements_section">
            <div className="flex_container">
                <p>Key Path 1</p>
                    <ul>
                        <p class="list_text">Leobien wakes up to the morning olfactory alarm clock of his coffee brewing. He looks at the time and wakes up his son. He hears a notification from his smartphone and looks over to check his transit App (Touchpoint 1). There are several major disruptions across the city so Leobien plans his day accordingly.</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 2</p>
                    <ul>
                        <p class="list_text">Leobien and his son walk to the bus station. Leobien speaks into his phone, “Any updates?”, as they arrive at the bus stop. (Touchpoint 2). His son has a presentation today so they practice while traveling for 20 minutes. A notification ding from the App lets them know they are a minute away from school. (Touchpoint 3).</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 3</p>
                    <ul>
                        <p class="list_text">He drops his son off at class and wishes him a good day but his son needs some reassurance from his father. Several minutes later, another notification dings from his smartphone notifying him he had missed his bus to go to work. (Touchpoint 4). It doesn’t matter, because his son comes first.</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 4</p>
                    <ul>
                        <p class="list_text">Leobien opens the app and it has automatically refreshed his transit map to get to work (Touchpoint 5). The next bus is 20 minutes away so he will be late for work.</p>
                    </ul>
            </div>
        </div>

        <div className="coc_requirements_section">
            <div className="flex_container">
                <p>Key Path 5</p>
                    <ul>
                        <p class="list_text">Leobien gets on the bus and puts on a podcast. About 30 minutes later, a notification lets him know he a minute away from the train station. He checks the app for tonight’s transit schedule to pick up his son from school before getting off the train (Touchpoint 6). The train arrives and he is crushed by the morning commute. Not great, but he is in a hurry so he has to get on. </p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 6</p>
                    <ul>
                        <p class="list_text">Leobien listens to his favorite podcast, “Super Awesome People in UX Design.” It is interrupted with a notification forcing a slight eye roll as he opens the App to see the grid is in chaos from a major subway delay (Touchpoint 7). Leobien is calm and collected and looks for other options. He smiles as the App connects him to the local ride-share program if he exists the transit hub (Touchpoint 8).</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 7</p>
                    <ul>
                        <p class="list_text">Leobien exists and opens the app again and hails a car from a car-sharing app. He travels for 15 minutes. A notification rings and lets him know he is a minute from work. Leobien says to his smartphone, “Close session.” (Touchpoint 9). Leobien gets to work almost on time despite the morning commute chaos while being able to be the father he needs to be.</p>
                    </ul>
            </div>
            
        </div>
        </div>
        





        
        </div>
   
);
};

export default Requirements3;



