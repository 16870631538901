import React from "react";
import asset5 from "../../../assets/athena_asset5.png";
import asset6 from "../../../assets/athena_asset6.png";
import asset7 from "../../../assets/athena_asset7.png";
import "./frameworks2.css";

const Frameworks2 = () => {
    return (
        
    <div className="section__padding container">
        <h1>FRAMEWORKS</h1>

    <div className="athena_frameworks section__padding2">
        <h2>Requirements</h2>
        <div>
            <div className="athena_flex_container">
                <p>Data</p>
                <div className="flex_list">
                    <ul>
                        <l1>student profiles </l1><br />
                        <l1>teacher profiles </l1><br />
                        <l1>health and wellness for students </l1><br />
                        <l1>grades </l1><br />
                        <l1>SMS </l1><br />
                        <l1>video  </l1><br />
                        <l1>health and wellness for students </l1><br />
                        <l1>lectures / slides / PDF</l1><br />
                    </ul>
                </div>
            </div>
            <div className="athena_flex_container">
                <p>Functional </p>
                <div className="flex_list">
                    <ul>
                        <l1>training/onboarding for facility </l1><br />
                        <l1>training/onboarding for parents </l1><br />
                        <l1>training/training/onboarding for parents </l1><br />
                        <l1>video conferencing</l1><br />
                        <l1>classroom messaging </l1><br />
                        <l1>individual messaging</l1><br />
                        <l1>meeting room for class </l1><br />
                        <l1>announcements for class</l1><br />
                        <l1>academic performance </l1><br />
                    </ul>
                </div>
            </div>
        </div>
        

    </div>
    <div className="athena_frameworks section__padding2">
        <h2>Element Additions</h2>
        <div>
            <div className="athena_flex_container">
                <p>Web App</p>
                <div className="flex_list">
                    <ul>
                        <l1>for general use when internet access is functioning </l1>
                    </ul>
                </div>
            </div>
            <div className="athena_flex_container">
                <p>Native App</p>
                <div className="flex_list">
                    <ul>
                        <l1>for all operating systems plus handheld devices  </l1> <br />
                        <l1>desktop and IOS/Andriod versions </l1><br />
                        <l1>can be used when broadband internet access is slow/offline </l1><br />
                        <l1>the software works on older computers / operating systems  </l1><br />
                    </ul>
                </div>
            </div>
            <div className="athena_flex_container">
                <p>Support</p>
                <div className="flex_list">
                    <ul>
                    <l1>integrated technical support for students / parents / teachers  </l1><br />
                    </ul>
                </div>
            </div>
            <div className="athena_flex_container">
                <p>Parent Portal</p>
                <div className="flex_list">
                    <ul>
                        <l1>separate access for parents to communicate with teachers and / or child when needed / technical support  </l1><br />
                    </ul>
                </div>
            </div>
        </div>
        
        
    </div>


    <div className="padding_container">
       <h2>Interaction Framework</h2>
       <p className="extra_padding">Form factor, posture, and input methods</p>
      
        <ol className="extra_length">
          <li className="extra_length">Native App (offline)</li>
          <li className="extra_length">Web-based App (online)</li>
          <li className="extra_length">Keyboard</li>
          <li className="extra_length">Mouse</li>
          <li className="extra_length">Touch screen</li>
        </ol>
      </div>


      <div className="athena_frameworks padding_container">
          <div>
       <h2>Google Classroom Design Function Elements </h2>
       <p className="extra_padding">Used Google Classroom as a boilerplate to add on function elements from our ethnographic research, modeling and context scenarios </p>
      
        <ul className="extra_length">
          <li className="extra_length">Navigation </li>
          <li className="extra_length">Classes</li>
          <li className="extra_length">Calendar </li>
          <li className="extra_length">Review</li>
          <li className="extra_length">Class List </li>

          <li className="extra_length">Settings</li>
          <li className="extra_length">Pages</li>
          <li className="extra_length">Stream </li>
          <li className="extra_length">Upcoming Assignments </li>
          <li className="extra_length">Stream Settings </li>

          <li className="extra_length">Classwork </li>
          <li className="extra_length">Google Drive</li>
          <li className="extra_length">People</li>
          <li className="extra_length">Teachers</li>
          <li className="extra_length">Students</li>
          <li className="extra_length">Grades</li>
        </ul>
        </div>
      <div className="athena_image_frameworks2">
            <img src={asset6} alt="display iamge of prototype" />
        </div>
    </div>
    <div className="athena_frameworks padding_container">
    
    <div>
       <h2>Revised Design Function Elements </h2>
        <div class="extra_paragraph_padding">
            <p>Onboarding Page</p>
            <ul>
            <li className="extra_length">Onboarding for students / teachers and parents  </li>
            </ul>
        </div>
        <div class="extra_paragraph_padding">
        <p>Technical Support Page</p>
        <ul>
          <li className="extra_length">24/7 techincal support for students / teachers and parents </li>
        </ul>
        </div>
        <div class="extra_paragraph_padding">
        <p>Interpersonal Skills Development Page </p>
        <ul>
          <li className="extra_length">Information for students to help them learn interpersonal skills outside the school setting </li>
        </ul>
        </div>
        <div class="extra_paragraph_padding">
        <p>Parent Portal </p>
        <ul>
          <li className="extra_length">Separate portal for parents in which they can access school information, speak directly to the teacher and communicate with their child directly if there are technical issues </li>
        </ul>
        </div>
        <div class="extra_paragraph_padding">
        <p>	Student Network Page  </p>
        <ul>
          <li className="extra_length">Students can message each other outside the school setting between classes and share stories, memes, images and videos to help with relax and unwind 
        </li>
        </ul>
        </div>
    </div>
    


      <div className="athena_image_frameworks2">
            <img src={asset7} alt="display iamge of prototype" />
        </div>
    </div>
    </div>

   
    );
  };
  
  export default Frameworks2;


