import React from "react";
import asset2 from "../../../assets/coc_asset2.png";

import "./modeling.css";

const Modeling = () => {
    return (
    <div className="container">
    <div className="section__padding2">
      <h1>MODELING</h1>
      <h2>Persona</h2>
      <p>Kara Thrace</p>
    </div>
    <div className="coc_modeling_section section__padding3">
        <div className="coc_modeling_list">
            <p class="persona_text">Quote</p>

            <p>Sometimes we must embrace that which opens up to us.</p>
            <p class="persona_text">Bio</p>
   
            <p>Kara is a web developer working for a startup company.
            She lives downtown in a new condo with her rescue pit bull.
            She worked her way through college as a waitress and had a
            very active social life. She struggled to know what she
            wanted to do with her career for several years but decided to
            go into freelance web development. After freelancing for
            several years, she was hired by an up-and-coming
            commercial startup. However, she was diagnosed with an autoimmune 
            condition that affected her work-life balance. </p>
            <br />
            <p>Kara is extremely passionate about her career to the
            point of putting her health on the sidelines. She is a
            borderline workaholic and spends a lot of her time at work.
            She feels that her health can put her job at jeopardy given her treatments for her MS can make her bedridden for days. </p>
            <br />
            <p>Kara believes firmly that her illness is manageable through allopathic and naturopathic means. She is dedicated to her health as she views her wellness and career as synergistic. </p>
            <br />
            <p class="persona_text">Goals</p>
            <ul>
                <li>Excel in career</li>
                <li>Put her autoimmune condition on hold</li>
                <li>Live flair free for 5 years</li>
            </ul>
            <p class="persona_text">Goals</p>
            <ul>
                <li> Her MS getting worse and stopping her from working </li>
                <li> Taking time off work</li>
                <li> Making enough money to support herself </li>
                <li> Finding better treatment options for MS </li>
            </ul>
        </div>
        <div className="coc_image_modeling">
            <img src={asset2} alt="display image of prototype" />
        </div>
    </div>
    </div>
    );
  };
  
  export default Modeling;




