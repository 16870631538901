import React from "react";
import './about.css'

const About2 = () => {
  return (
    <div className="container section__padding">
 
      <div>
      <h1 className="extra_padding">About</h1>
       <p className="extra_padding">I am a multidisciplined designer focusing on user experience and interaction design. I am passionate about humanizing the digital experience through design thinking methodologies since it employs the most powerful way to create dynamic human-centered digital products. I have an extensive background in photography, management, film production and user experience that allowed me to work across Canada. I am currently seeking to work in a fast-paced environment in which I can utilize my skillset in companies that share my core belief that technology can advance the human condition in a proactive and meaningful way.  </p>
      
       <p className="extra_padding">I graduated from NSCAD University where I gained valuable work experience in Nova Scotia’s film industry in independent and commercial productions. I also worked for the Atlantic Film Festival (AFF) and William F. Whites. I was a board member of AFCOOP (Atlantic Filmmakers Cooperative) to help emerging filmmakers.</p>

       <p className="extra_padding">I continued to work in film and TV as an IATSE667 member on popular Canadian Television shows such as Flashpoint (CTV), The Best Years, (Global) and The Latest Buzz, (YTV). I  have worked on over 25 commercial and independent film productions in Nova Scotia and Ontario.</p>

       <p className="extra_padding">I opened my first portrait studio in Vancouver’s Historic Gastown, working with top talent agencies (Wilhelmina) and (Inspirational Talent). I also worked for high-profile B.C. Realtors (Royal Lepage Real Estate - Kelowna) and (Vantage West - Kelowna).</p>

       <p className="extra_padding">I started my second BFA (IxD Sheridan College) while continuing my photography career in Toronto in 2019. I am currently working as a commercial photographer and social media content creator for the business sector (Forest Hill Reality while finishing my work as a contractor for Sheridan College as a Jr. Web Developer for an SRCA Research Project memorializing Chinese exclusion from immigration policies in the 20th century. </p>

        <p className="extra_padding">My design ethos is that of a storyteller, exploring objectivity and truth because beauty lies in honestly and not cold-hearted algorithms that dictate much of lives. Powerful stories are formed when the internal is valued more than the external, the microcosm over the macrocosm. My work focuses on what unites us through empathy, compassion and common understanding.</p>

        <p>eeisenstadt@gmail.com</p>
        <p>416.904.9755</p>
        
        </div>

    </div>
  );
};

export default About2;