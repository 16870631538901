import React, { useEffect } from 'react';
import './App.css';
import Coc from './page/coc';
import Athena from './page/athena';
import Rapid from './page/rapid';
import Aboutme from './page/aboutme';
import Landing from './page/landing';
import Cv from './page/cv';

import {BrowserRouter as Router ,Routes, Route} from 'react-router-dom'


const App = () => {

    return (
        <Router>
            <Routes>
                <Route  exact path='/' element={<Landing/>} />
                <Route  exact path='/athena' element={<Athena/> } />
                <Route  exact path='/rapid' element={<Rapid/> } />
                <Route  exact path='/coc' element={<Coc/> } />
                <Route  exact path='/about' element={<Aboutme/> } />
                <Route  exact path='/cv' element={<Cv/> } />
            </Routes>
        </Router>
    )
}

export default App;


