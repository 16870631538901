
import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.svg';
import './nav.css';

const Athena_nav = () => {

  return (
  
    <div className="navbar nav_padding container">
      <div className="navbar-links">
       
        <div className="navbar-links_container">
          <p>UXUI</p>
          {/* <p><a href="#wgpt3">Modeling</a></p>
          <p><a href="#possibility">Requirements</a></p>
          <p><a href="#features">Scenarios</a></p>
          <p><a href="#blog">Framework</a></p>
          <p><a href="#blog">Prototype</a></p> */}
        </div>
      </div>

      <div className="navbar-links_logo">
        <a href="../../"><img src={logo} /></a>
      </div>

    </div>
  );
};

export default Athena_nav;
