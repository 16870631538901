import React from "react";
import asset3 from "../../../assets/athena_asset3.png";
import asset2 from "../../../assets/athena_asset2.png";
import asset4 from "../../../assets/athena_asset4.png";
import './modeling2.css'

const Modeling2 = () => {
  return (
    <div className="container section__padding">
    <div className="athena_modeling">

      <div className="athena_header_section">
        <h1>MODELING</h1>
        <h3>Behavioral Variables</h3>
        <p className="extra_padding2">These variables were gathered from the unstructured ethnographic interviews.</p>
       
        <h4>Behavioral Axis for LMS System (Parents)</h4>

        <div className="athena_image_axis container">
            <img src={asset2} alt="display iamge of prototype" />
        </div>
       
          <div className="method_container">
            <h3 className="extra_padding">Synthesize User Goals</h3>
            <ol className="extra_length">
              <li className="extra_length">Create an interactive environment for students that enhances academics alongside interpersonal skills</li>
              <li className="extra_length">Have accessible technical support available at all times </li>
              <li className="extra_length">Have proper accountability coordination between school, parent and student</li>
              <li className="extra_length">Have proper training for teachers and parent</li>
              <li className="extra_length">Have students use their bodies more often and limit screen time when possible</li>
            </ol>
      </div>
      
      <div className="method_wrapper">
        <div>
          <h2>Persona 1 </h2>
          <p>Kara Thrace | Parent </p>
            <p className="persona_text">Quote</p>
            <p>Push yourself always, because no one else is going to do it for you.</p>
            <p className="persona_text">Bio</p>
   
            <p>Deanna is a restaurant manager working without a functional staff during the COVID19 Pandemic. She is a single mother renting downtown Toronto with her rescue pit bull. She worked her way through college as a waitress and had a very active social life. She struggled to know what she
              wanted to do with her career for several years but decided to
              go into restaurant management to provide for her daughter. </p>
            <br />
            <p> She is extremely passionate about her being a mother and would do anything to help her daughter. Her work schedule is fluid and unpredictable which causes pain points in her relationship with her daughter. Furthermore, with her daughter at home learning fully online, it has become very difficult for her to manage her family and work-life creating friction and stress.  </p>
            <br />
            <p className="persona_text">Goals</p>
            <ul>
                <li>Excel in career</li>
                <li>Earn more money</li>
                <li>Buy modern computer equipment </li>
                <li>Help her daughter succeed in school </li>
                <li>Get a new job where she can work from home </li>
            </ul>
            <p className="persona_text">Concerns</p>
            <ul>
                <li> Not being accountable for her daughter </li>
                <li> Not having proper broadband on her rental property</li>
                <li> Worried about her daughter's social life and interpersonal skills</li>
            </ul>
          </div>
        <div className="athena_image_persona">
            <img className="hidemob" src={asset3} alt="display image of prototype" />
            {/* <img className="hidepc" src={asset4} alt="display image of prototype" /> */}
        </div>
      </div>
    
    <div className="method_wrapper">
  
      <div className="athena_image_persona2">
            <img className="hidemob" src={asset4} alt="display image of prototype" />
        </div>
        
        <div>
        <h2>Persona 2 </h2>
        <p>Sharon Agathn | Student</p>
            <p className="persona_text">Quote</p>

            <p>Love makes your soul crawl out of its hiding place. </p>
            <p className="persona_text">Bio</p>
   
            <p>Sharon is a student who is being homeschooled during the COVID19 pandemic. She is very smart and loves to learn but struggles in the digital environment.  She has ADHD and constant zoom lessons have been difficult on his ability to stay focused and complete assignments.</p>
            <br />
            <p> Sharon is fun-loving and loves playing with her friends in the park. She excels in using her body and being hands-on which is why she is talented at team sports. At the height of the pandemic, she developed depression when he was forced not to participate in his sporting activities. This also affected her performance in school. </p>
            <br />
           
            <p className="persona_text">Goals</p>
            <ul>
                <li>Join a sports team </li>
                <li>Get a B- average in school</li>
                <li>Get a new laptop so they can work at school outside  </li>
                <li>Exercise more  </li>
                <li>Learn to focus more during school sessions  </li>
      
            </ul>
            <p className="persona_text">Concerns</p>
            <ul>
                <li> Doing well in school </li>
                <li> Not being able to concentrate during their zoom lessons </li>
                <li> Not having enough socialization  </li>
       
            </ul>

            {/* <img className="hidepc" src={asset3} alt="display image of prototype" /> */}

        </div>
    </div>
    </div>
    </div>
    </div>

  );
};

export default Modeling2;