
import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/uxdevlogo.svg';
import react from '../../assets/react_icon.svg';
import './landing.css'

const Landing = () => {
   

    return (
    <div className="landing_container">
        <div className="landing_navbar">
            <div className="navbar-links">
                <p>evan eisenstadt</p>
                <p className="evan_text"> made in</p>
                </div>
            <div className="navbar-links_logo">
            <img src= { react } />
            </div>
        </div>

        <div className="lowbar">
  
            <div className="lowbar-links_logo">
                <img src={ logo } />
            </div>
            
            <div className="project_list">
                <p><a href="../../coc">coc app | <span class="font_change">product design</span></a></p>
                <p><a href="../../athena">athena lms | <span class="font_change">ixd</span></a></p>
                <p><a href="../../rapid">rapid app | <span class="font_change">ixd</span></a></p>
            </div>

            <div className="about_list">
                <p><a href="../about">about</a></p>
                <p><a href="../cv">cv</a></p>
            </div>
            </div>
        </div>
  );
};

export default Landing;


