import React from "react";
import './cv.css'

const Cv2 = () => {
  return (
    <div className="container section__padding">
 
      <div>
      <h1 className="extra_padding">CV</h1>
       
      <div className="employment_container">
       <h3>WEB DEV | UX RESEARCHER</h3>
       <h4>tcgpr | 2018 - 2022</h4>
       <p>tcgpr is a Toronto-based PR and Marketing Communications Consulting Firm focusing on real estate in the GTA.  Worked on front-end development for this firm and developed research studies for MLS users (real estate). Their client list includes Ontario's biggest real estate developers including Davpart Inc, Caraco, Marlin Springs and Podium Developments. </p>
      </div>

      <div className="employment_container">
       <h3>COMMERCIAL PHOTOGRAPHER | VIDEOGRAPHER </h3>
       <h4>Forest Hill Yorkville | 2017 - 2022</h4>
       <p>Specialize in branding portraits that focus on individual needs while falling within the company marketing ethos. Furthermore, developing social media content strategy for web-based media through photography and videography. </p>
      </div>
        
        
      <div className="employment_container">
       <h3>Evan Eisenstadt Photography</h3>
       <h4>Forest Hill Yorkville | 2016 - current</h4>
       <p>Specializing in commercial and portrait photography featured in Toronto Star, Condolife, Homes Publishing, CoStar and Homes Magazine. </p>
      </div>

      <div className="employment_container">
       <h3>Learn Photography Canada</h3>
       <h4>SENIOR PHOTOGRAPHY INSTRUCTOR  | 2019 - 2021</h4>
       <p>Specializing in commercial and portrait photography featured in Toronto Star, Condolife, Homes Publishing, CoStar and Homes Magazine. </p>
      </div>
      <div className="employment_container">
       <h3>Sheridan College</h3>
       <h4>JR FRONT END DEVELOPER | 2020</h4>
       <p>Specializing in commercial and portrait photography featured in Toronto Star, Condolife, Homes Publishing, CoStar and Homes Magazine. </p>
      </div>

      <div className="employment_container">
       <h3>Basil & Mint </h3>
       <h4>RESTAURANT MANAGER | 2016 - 2018</h4>
       <p>Top-10 restaurant (Tripadvisor 2019 / 2020 / 2021 ) located in beautiful Kelowna, BC. Management duties include general operations, payroll and staff management. Furthermore, developing the beverage menu with an extensive wine list that features premium wines from across British Columbia. </p>
      </div>

      <div className="employment_container">
       <h3>La Perla </h3>
       <h4>SERVICE DIRECTOR | BAR MANAGER   | 2014 - 2018</h4>
       <p>Managed this restaurant lounge located on Queen West. Duties include a variety of tasks from finance and payroll to event management and service director for the night club which had weekly LGBT-focused events for the queen west community. </p>
      </div>

      <div className="employment_container">
       <h3>Vantage West </h3>
       <h4>MLS COMMERCIAL PHOTOGRAPHER | 2016 - 2018</h4>
       <p>Luxury real estate photography MLS listings and social media content creation for the agency. Content creation for engagement in social media campaigns while working within tight time constraints for clients.  </p>
      </div>

      <div className="employment_container">
       <h3>IATSE 667 Toronto</h3>
       <h4>CAMERA ASSISTANT | 2012 - 2014</h4>
       <p>Camera assistant (AC) for Toronto Local Film Union IATSE667. Worked on CTV “Flashpoint”, CBC “The Best Years”, YTV “The Latest Buzz”. Furthermore, worked on over 25 independent film productions located in Nova Scotia and Ontario.</p>
      </div>

      <div className="employment_container">
       <h3>eeisenstadt@gmail.com</h3>
       <h3>416.904.9755</h3>
      </div>

        </div>
    </div>
  );
};

export default Cv2;