import React from "react";
import './requirements2.css'
import asset5 from "../../../assets/athena_asset5.png";


const Requirements2 = () => {
  return (
    <div className="container section__padding">
    <div className="section_padding">
      <h1>REQUIREMENTS</h1>
    </div>


    <div className="athena_requirements section__padding2">
        <h2 className="athena_midwidth">Problem+</h2>
        <div>
            <div className="athena_container">
                <p>Needs Change</p>
                <div className="coc_flex1_list">
                    <ul>
                        <l1>Consumers are not given proper information about how food and nutritional supplementation can affect their genetic genome creating pain points in their health and wellness. </l1>
                    </ul>
                </div>
            </div>
            <div className="athena_container">
                <p>User Issues</p>
                <div className="coc_flex1_list">
                    <ul>
                        <l1>People are unaware that different food and supplements can affect their health individual health according to their genetic markers in a process called nutrigenetics. </l1>
                    </ul>
                </div>
            </div>
            <div className="athena_container">
                <p>Impact</p>
                <div className="coc_flex1_list">
                    <ul>
                        <l1>Disease induced by foodstuffs is a massive strain on Canadian society in which individuals know very little about. Furthermore, recent developments in science and technology are not being utilized to help people live longer and heather lives by altering their diet to incorporate their genetic markers. </l1>
                    </ul>
                </div>
            </div>
            </div>
    </div>

    <div className="athena_requirements section__padding2">
    <h2 className="athena_midwidth">Vision+</h2>
        <div>
            <div className="athena_container">
                <p>Will Change</p>
                <div className="coc_flex1_list">
                    <ul>
                        <l1>Consumers are given accessible information about their health and wellness according to their genetic markers that will be enhanced by their foodstuff.</l1>
                    </ul>
                </div>
            </div>
            <div className="athena_container">
                <p>User Gain</p>
                <div className="coc_flex1_list">
                    <ul>
                        <l1>Users will gain a greater chance of health and longevity in their lives by utilizing their genetics with an accessible dynamic app that helps them manage their food and supplement requirements. </l1>
                    </ul>
                </div>
            </div>
            <div className="athena_container">
                <p>Benefits</p>
                <div className="coc_flex1_list">
                    <ul>
                        <l1>The benefits range from the individual to the macro in which greater health and wellness through individual accountability will enhance all aspects of the uses life through optimal health and wellness. </l1>
                    </ul>
                </div>
            </div>
            </div>
            </div>

        <h1>SCENARIOS</h1>
        <div className="athena_image_requirements">
            <img src={asset5} alt="display iamge of prototype" />
        </div>

        <div className="coc_scenarios section__padding">
        <div className="coc_requirements_section">
            <div className="flex_container">
                <p>Key Path 1</p>
                    <ul>
                        <p class="list_text">Deanna wakes up from a long fun weekend spending time with her daughter. She is tired but eager to start the morning with her daughter before going to work.</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 2</p>
                    <ul>
                        <p class="list_text">After a healthy breakfast and a quick morning walk with their dog, she watches as her daughter logs herself on her web-based LMS Software App.</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 3</p>
                    <ul>
                        <p class="list_text">Deanna goes to work, but has her notifications on in case there are issues with her daughter's LMS Software.</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 4</p>
                    <ul>
                        <p class="list_text">Several hours later, Deanna is notified that the broadband access to her apartment has been weak via her LMS App for Parents which is connected to her daughter's account.  </p>
                    </ul>
            </div>
        </div>

        <div className="coc_requirements_section">
            <div className="flex_container">
                <p>Key Path 5</p>
                    <ul>
                        <p class="list_text">Deanna can contact her daughter who informs her she is having trouble staying connected to her zoom class. Deanna can send a message to her teacher about the situation. Her daughter is very concerned about not being in class but Deanna can calm her down saying she has been in contact with her teacher.</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 6</p>
                    <ul>
                        <p class="list_text">Deanna tells her daughter to switch to the Desktop-based LMS system while the internet is being unstable.</p>
                    </ul>
            </div>
            <div className="flex_container">
                <p>Key Path 7</p>
                    <ul>
                        <p class="list_text">Deanna later comes home to her daughter doing a Zumba routine with her friend from class. The LMS Software has a mentorship program in which students can mentor other students in which they excel on. She smiles and begins to make dinner.</p>
                    </ul>
            </div>
        
        <div className="flex_container">
                <p>Key Path 8</p>
                    <ul>
                        <p class="list_text">Deanna can view the daily report sent from the school in which her daughter did very well, however that is not a surprise. </p>
                    </ul>
            </div>
       
    </div>
    <div className="coc_requirements_header section__padding">
    

        </div>
        </div>
        </div>
   
    
    

);
};

export default Requirements2;



