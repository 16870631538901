import React, { useEffect } from "react";
import { Header, Research, Modeling, Requirements, Scenarios, Frameworks, Prototype, Branding } from '../containers/coc';
import '../components/nav/nav.css';
import logo from '../../src/assets/logo.svg';

const Coc=()=>{
    useEffect(()=>{},[])
    return(
        <div className="app1">
        <div className="navbar nav_padding container">
            <div className="navbar-links">
       
            <div className="navbar-links_container ">
            <p><a href="#coc_modeling">Modeling</a></p>
            <p><a href="#coc_requirements">Requirements</a></p>
            <p><a href="#coc_scenarios">Scenarios</a></p>
            <p><a href="#coc_frameworks">Framework</a></p>
            <p><a href="#coc_prototype">Prototype</a></p>
            </div>
      </div>

      <div className="navbar-links_logo">
        <a href="../../"><img src={logo} /></a>
      </div>

    </div>

        <div id="coc_header">
            <Header />
        </div>
        <div id="coc_research">
            <Research />
        </div>
        <div id="coc_modeling">
            <Modeling />
        </div>
        <div id="coc_requirements">
            <Requirements />
        </div>
        <div id="coc_scenarios">
            <Scenarios />   
        </div>
        <div id="coc_frameworks">
            <Frameworks />   
        </div>
        <div id="coc_prototype">
            <Prototype />  
        </div>
        <div id="coc_branding">
            <Branding />  
        </div>
        </div>
    )
}

export default Coc;

