import React from "react";
import asset1 from "../../../assets/coc_asset1.png";
import './header.css'

const Header = () => {
  return (
    <div className="coc_header section__padding2 container">
      <div className="coc_header_section">
        <p class="coc_header_text">COC App</p>
        <h3>Circle of Care App tracks your daily intake of nutrients, vitamins and minerals from your diet and compares that with genetic markers in your genome for optimal health and wellness.</h3>
      </div>
      <div className="coc_image" >
          <img src={asset1} alt="display iamge of prototype" />
      </div>
    </div>
  );
};

export default Header;