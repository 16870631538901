import React from "react";
import asset8 from "../../../assets/coc_asset8.png";
import asset9 from "../../../assets/coc_asset9.png";
import asset10 from "../../../assets/coc_asset10.png";
import asset11 from "../../../assets/coc_asset11.png";
import asset12 from "../../../assets/coc_asset12.png";
import "./branding.css";

const Branding = () => {
    return (
    <div className="container">
    <div className="section__padding">
      <h1>Branding</h1>
    </div>
    
    <div className="coc_branding_container section__padding">
        <h2>Typographic Scale</h2>
        <div className="coc_image_branding">
            <img src={asset8} alt="display image of prototype" />
        </div>
    </div>
    
    <div className="coc_branding_container section__padding">

        <h2>Logo</h2>
        <div className="coc_image_branding">
            <img src={asset11} alt="display image of prototype" />
        </div>
    </div>
        
    <div className="coc_branding_container section__padding">

        <h2>Font</h2>
        <div className="coc_image_branding">
            <img src={asset10} alt="display image of prototype" />
        </div>
    </div>
        
    <div className="coc_branding_container section__padding">

        <h2>Social</h2>
        <div className="coc_image_branding">
            <img src={asset9} alt="display image of prototype" />
        </div>
        </div>
        
    <div className="coc_branding_container section__padding">

        <h2>Colour Scheme</h2>
        <div className="coc_image_branding">
            <img src={asset12} alt="display image of prototype" />
        </div> 
        </div>
        </div>
    );
  };
  
  export default Branding;




