import React from "react";
import "./research.css";
import asset13 from "../../../assets/coc_asset13.png";

const Research = () => {
  return (
    <div className="container">
    <div className="section__padding2">
      <h1>RESEARCH</h1>
      <h2>SME Interview Naturopathic Doctor </h2>
      <p>Ethnographic Interview @ Sunnybrook Hospital Ho - Dr. Thep Khanderpor  </p>
    </div>


    <div className="coc_research_section section__padding2">
        <div className="padding_bottom">
            <h3>“About 15% of Food Court has disease-fighting food in a hospital. The Sunnybrook food court does an insignificant job at providing nutrient density and disease-fighting foods to patients, staff members and visitors.”</h3>
            <p>-Dr. Thep Khanderpor</p>
        </div>
        
        <div>
            <div className="flex_container padding_bottom">
                <p className="flex_paragraph">Offers</p>
                <div className="flex_list">
                    <ul>
                        <l1>Processed pastries </l1><br />
                        <l1>Hot dogs</l1><br />
                        <l1>High fructose corn syrup</l1><br />
                        <l1>Processed meats</l1><br />
                        <l1>Trans fats</l1><br />
                        <l1>Pretzels</l1><br />
                        <l1>Fries </l1><br />
                        <l1>Syrup</l1><br />
                        <l1>Salad</l1><br />
                        <l1>Chips</l1><br />
                        <l1>Chocolate </l1><br />
                        <l1>Muffins</l1><br />
                        <l1>Candy</l1><br />
                    </ul>
                </div>
            </div>
            <div className="flex_container padding_bottom">
                <p className="flex_paragraph">Recommend</p>
                <div className="flex_list">
                    <ul>
                        <l1>Fruit </l1><br />
                        <l1>Vegetables </l1><br />
                        <l1>Fresh salads / fresh salad bar</l1><br />
                        <l1>Syrup</l1><br />
                        <l1>Healthy comfort foods / no trans fats</l1><br />
                        <l1>Probiotics based foods </l1><br />
                        <l1>More diverse selections </l1><br />
                        <l1>Seafood </l1><br />
                        <l1>Whole grains </l1><br />
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p className="flex_paragraph">Focus On</p>
                <div className="flex_list">
                    <ul>
                        <l1>Monounsaturated fats  </l1><br />
                        <l1>Polyunsaturated fats</l1><br />
                        <l1>Fresh salads / fresh salad bar</l1><br />
                        <l1>Complex Carbohydrates</l1><br />
                        <l1>Healthy Proteins</l1><br />
                        <l1>Selection of essential AAs</l1><br />
                        <l1>Selection of nonessential AAs</l1><br />
                        <l1>Fruits</l1><br />
                        <l1>Vegetables</l1><br />
                     </ul>
                </div>
            </div>
            </div>
            </div>
            <div className="coc_research_header section__padding">
                    <h2>Questionnaire  </h2>
                    <p>Software: GoogleForms</p>
                        <br />
                    <h3>The questionnaire was developed with Dr. Khanderpor after a qualitative interview at Sunnybrook Hospital Food Court. </h3>
                    <p>Questionnaire</p>
            </div>
            <div className="coc_research_questionnaire section__padding2">
                    <ul>
                        <li>How would you rate your habits of eating healthy foods?</li>
                        <li>How many times a day did you eat processed food?</li>
                        <li>How many servings of fresh fruit do you eat a day?</li>
                        <li>How many servings of fresh vegetables do you eat a day?</li>
                        <li>How many sugar beverages did you drink each day?</li>
                        <li>How many times a day did you eat protein?</li>
                        <li>How many times a day did you eat dairy products?</li>
                        <li>How many times a day did you eat trans fats?</li>
                        <li>How many times do you eat wild-caught seafood?</li>
                        <li>How many times do you drink alcohol a week?</li>
                        <li>How many times do you eat fast food per week?</li>
                    </ul>
                <div className="graph_image" >
                        <img src={asset13} alt="graph" />
                </div>
                
        </div>
        <div className="coc_research_header section__padding">
                    <h2>Literature Review  </h2>
                    <p>Canada’s Dietary Guidelines Report Audit</p>
        </div>


        <div className="coc_research_section section__padding2">
        <div className="padding_bottom">
        <h3>The report states that nutritious foods and beverages are the foundation for healthy eating while types of foods and beverages can harm health when consumed regularly.</h3>
        </div>

            <div>
            <div className="flex_container padding_bottom">
            <p className="flex_paragraph">Key Point 1</p>
            <div className="flex_list">

                    <ul>
                        <l1>Nutritious foods are the foundation for healthy eating and living. </l1>
                    </ul>
                </div>
            </div>

            <div className="flex_container padding_bottom">
            <p className="flex_paragraph">Key Point 2</p>
            <div className="flex_list">
                    <ul>
                        <l1>Processed or prepared foods and beverages that contribute to excess sodium, free sugars, or saturated fat undermine healthy eating and should not be consumed regularly</l1>
                    </ul>
                </div>
            </div>
            <div className="flex_container padding_bottom">

            <p className="flex_paragraph">Key Point 3</p>
            <div className="flex_list">
                    <ul>
                        <l1>Food skills are needed to navigate the complex food environment and support healthy eating. </l1>
                     </ul>
                </div>
            </div>
            <div className="flex_container padding_bottom">

            <p className="flex_paragraph">Key Point 4</p>
            <div className="flex_list">
                    <ul>
                        <l1>Implementation requires nutritious foods be available and accessible </l1>
                    </ul>
                </div>
            </div>
            </div>
            </div>
            </div>
  );
};

export default Research;













