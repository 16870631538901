import React from "react";
import './method3.css'
import asset2 from "../../../assets/rapid_asset1.png";


const Method3 = () => {
  return (
    <div className="container section__padding">
 
      <div>
      <h1 className="extra_padding">RESEARCH METHODOLOGY</h1>
       <p className="extra_padding">Our team used ethnographic interviews with a script that encourages qualitative information to create the interaction framework of this digital application. </p>
      
      <div className="padding_container">
       <h2>1st User Interview Highlights</h2>
       <p className="extra_padding">Humber College Student | Rides Transit Daily </p>
      
       <h4>User Capacity on Subways</h4>
        <ul className="extra_length">
          <li className="extra_length">Subject is concerned about the number of people using transit during the COVID-19 pandemic. </li>
        </ul>

        <h4>Nervousness (COVID)</h4>
        <ul className="extra_length">
          <li className="extra_length">Subject is concerned to be in close proximity of people in tight spaces such as buses and subways in fear of catching COVID </li>
        </ul>

        <h4>Ridding Transit at Night  </h4>
        <ul className="extra_length">
          <li className="extra_length">Using transit at night causes frustrations since there are few security personnel in many stations and not many ways to contact for immediate help</li>
        </ul>


        <h4>Money  </h4>
        <ul className="extra_length">
          <li className="extra_length">Using transit at night causes frustrations since there are few security personnel in many stations and not many ways to contact for immediate help</li>
        </ul>
        < br />

      </div>

      <div className="padding_container">
        <h2>2nd User Interviews Hightlights</h2>
       <p class="extra_padding"> Restaurant Manager  | Rides Transit Daily </p>
      
        <h4>Transit Waiting Times</h4>
        <ul className="extra_length">
          <li className="extra_length">Transit waiting times are often inaccurate and inaccessible during the commute causing pain points throughout the day since their work is time-sensitive</li>
        
        </ul>

        <h4>COVID Issues on Transit</h4>
        <ul className="extra_length">
          <li className="extra_length">User is concerned about transferring COVID to their family if he contracts virus on transit </li>
          
        </ul>

        <h4>Money</h4>
        <ul className="extra_length">
          <li className="extra_length">Transit pass is very expensive when you have to factor in other members of the family</li>
         
        </ul>


        <h4>Missed Connections </h4>
        <ul className="extra_length">
          <li className="extra_length">Connections are often missed which could ad 45minutes extra to their commute</li>
          
        </ul>
        < br />
        </div>
        <div className="padding_container">
        <h4>Behavioral Axis for Interview Subjects</h4>

<div className="athena_image_axis container">
    <img src={asset2} alt="display iamge of prototype" />
</div>
  
      </div>
    </div>
    </div>
  );
};

export default Method3;