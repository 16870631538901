import React from "react";
import { Header, Method, Modeling2, Requirements2, Frameworks2 } from '../containers/athena';
// import { Athena_nav } from '../components';
import '../components/nav/nav.css';
import logo from '../../src/assets/logo.svg';

const Athena=()=>{
    return (
    <div className="app2">
   <div className="navbar nav_padding container">
            <div className="navbar-links">
       
            <div className="navbar-links_container ">
            <p><a href="#athena_modeling2">Modeling</a></p>
            <p><a href="#athena_requirements2">Requirements</a></p>
            <p><a href="#athena_frameworks2">Scenarios</a></p>
            <p><a href="#athena_frameworks2">Framework</a></p>
            <p><a href="#athena_frameworks2">Prototype</a></p>
            </div>
      </div>

      <div className="navbar-links_logo">
        <a href="../../"><img src={logo} /></a>
      </div>

    </div>
    <div id="athena_header">
        <Header />
    </div>
    <div id="athena_method">
        <Method />
    </div>
    <div id="athena_modeling2">
        <Modeling2 />
    </div>
    <div id="athena_requirements2">
        <Requirements2 />
    </div>
    <div id="athena_frameworks2">
        <Frameworks2 />  
    </div>
    </div>
    )
}

export default Athena;