import React from "react";
import './method.css'

const Method = () => {
  return (
    <div className="container section__padding">
 
      <div>
      <h1 className="extra_padding">RESEARCH METHODOLOGY</h1>
       <p className="extra_padding">Our team is using several types of research methods to explore and gather the information that we are going to analyze and interpret for the UX research study. We are going to be performing <em>unstructured interviews</em> with a script that encourages qualitative information followed by a <em>questionnaire.</em> We are interested in ethnographic information to help us understand the behavioral attributes and motivations behind our research proposal.</p>
      
      <div className="padding_container">
       <h2>1st User Interview Highlights</h2>
       <p className="extra_padding">Student | Slate CRM System (Sheridan College)</p>
      
        <ul className="extra_length">
          <li className="extra_length">Many apps within Slate that are older versions of web-based Apps</li>
          <li className="extra_length">No direct technical support for issues</li>
          <li className="extra_length">Needs high-speed broadband or system will not load</li>
          <li className="extra_length">The system is often down for technical issues and/or maintenance creating friction for students when dealing with deadlines and online class</li>
          <li className="extra_length">The video portal is slow and hard to navigate </li>
          <li className="extra_length">UI seems dated and does not follow proper user experience methodologies </li>
          <li className="extra_length">Page loading is slow and often corrupted  </li>
          <li className="extra_length">Lack of customization for the user  </li>
          <li className="extra_length">Email and messaging system is hard to access and sync with external mail applications </li>
        </ul>
      </div>

      <div className="padding_container">
        <h2>2nd User Interviews Hightlights</h2>
       <p class="extra_padding"> Parent | Google Classroom </p>
      
        <h4>Equity Issues</h4>
        <ul className="extra_length">
          <li className="extra_length">Systematic issues such as computer literacy and broadband access are being assumed by school facility </li>
          <li className="extra_length">Many families do not have new computers with up-to-date operating systems </li>
          <li className="extra_length">Quality of instruction to use and problem solve technical issues on LMS</li>
          <li className="extra_length">Many families do not have broadband access and/or have to many people sharing the same connection</li>
        </ul>

        <h4>Accountability Issues</h4>
        <ul className="extra_length">
          <li className="extra_length">Friction to whom is accountable for the child to do their work and be engaged in class</li>
          <li className="extra_length">Parents who work full-time don't have the time to be accountable for their child's work ethic </li>
        </ul>

        <h4>Childhood Development </h4>
        <ul className="extra_length">
          <li className="extra_length">School is not solely a testing ground for academic performance, but a place to learn interpersonal skills</li>
          <li className="extra_length">Interpersonal skills are not being learned online </li>
        </ul>


        <h4>Zoom Fatigue </h4>
        <ul className="extra_length">
          <li className="extra_length">Staring at a screen for long extended periods creates exhaustion in students</li>
          <li clasName="extra_length"> Allowing space for students to move their bodies and exercise to combat this </li>
        </ul>
        < br />
        </div>
        <div className="padding_container">
        <h2 className>Questionnaire </h2>
        <ol className="extra_length">
          <li className="extra_length">How capable do you think you are to supporting your child’s learning at home during the pandemic?</li>
          <li className="extra_length">What level of confidence do you have in the schools' ability to provide the same level of performance as in-person compared to e-learning?</li>
          <li className="extra_length">Do you think your child is getting the same life skills online when compared to in-person learning? </li>
          <li className="extra_length">Do you think Zoom fatigue is interfering with your child's learning ability? </li>
          <li className="extra_length">Do you think your child can focus entirely on academic work when they are doing it at home?</li>
          <li className="extra_length">Do you promote your child to participate in extracurricular activities? </li>
          <li className="extra_length">Do you have the technical resources as a parent to solve problems when dealing with e-learning software? </li>
          <li className="extra_length">Is using Zoom problematic due to your environment? </li>
          <li className="extra_length">Do you have access to a fast enough internet connection for the LMS software? </li>
          <li className="extra_length">Does the school help you effectively understand and troubleshoot the LMS software?  </li>
        </ol>
      </div>

      <div className="padding_container">
      <h2 className>Questionnaire Iteration</h2>
      <p class="extra_padding">We changed several questions from our first survey since parents in our interviews were very concerned about general life skills and systemic issues facing their children. Our first survey had more questions focused on academic performance which we replaced to focus on our research findings. </p>
      
      <ol className="extra_length">
          <li className="extra_length">How capable do you think you are to supporting your child’s learning at home during the pandemic?</li>
          <li className="extra_length">What level of confidence do you have in the schools' ability to provide the same level of performance as in-person compared to e-learning?</li>
          <li className="extra_length">Has your child struggled academically at home using e-learning?</li>
          <li className="extra_length">Do you think Zoom fatigue is interfering with your child's learning ability?</li>
          <li className="extra_length">Do you think your child can focus entirely on academic work when they are doing it at home?</li>
          <li className="extra_length">Do you promote your child to participate in extracurricular activities when you are able too?</li>
          <li className="extra_length">Do you have the technical resources as a parent to solve problems when dealing with e-learning software?</li>
          <li className="extra_length">What level of confidence do you have in your ability to make the school meet your child’s learning needs? </li>
          <li className="extra_length">How capable do you think you are to supporting your child’s learning at home?</li>
          <li className="extra_length">How aware are you of your child’s academic and extracurricular achievements?</li>
        </ol>
      
      </div>
    </div>
    </div>
  );
};

export default Method;