import React from "react";
import asset8 from "../../../assets/athena_asset8.png";
import './header.css'

const Header = () => {
  return (
    <div className="container section__padding">
    <div className="athena_header section__padding">
      <div className="athena_header_section">
      <p class="coc_header_text">Athena App</p>
      <div className="athena_padding_bottom">
       <h2>Problem </h2>
       <p>LMS systems create friction and pain points for students and parents because of lack of technical literacy and computer-based equity, lack of meaningful technical support, lack of accountability and a lack of general socializing and engagement between students. </p>
       </div>
       <div className="athena_padding_bottom">
       <h2>Solution </h2>
       <p>The LMS system is equitable in nature by that offers accessible technical support while accessible on all platforms and devices; furthermore has native and web-based applications in case of broadband internet access disruption and has a parent-access portal in which parents can help be accountable for their children. </p>
       </div>
      </div>
      <div className="athena_image" >
          <img src={asset8} alt="display iamge of prototype" />
      </div>
    </div>
    </div>
  );
};

export default Header;