import React from "react";
import asset2 from "../../../assets/rapid_asset2.png";
import './header3.css'

const Header3 = () => {
  return (
    <div className="container section__padding">
    <div className="athena_header section__padding">
      <div className="athena_header_section">
      <p class="coc_header_text">Rapid App</p>
      <div className="athena_padding_bottom"></div>
       <h2>Problem </h2>
       <p>Currently, transit systems are not accessible and safe due to inaccurate
wait times and the inability for users to socially distance on buses and
trains during the global pandemic. </p>
      
       <h2>Vision </h2>
       <p>Up-to-date transit data about wait-times, flexible transit options and user
occupancy are given to the passenger so they can make informed
decisions about traveling during the global pandemic. </p>
      
      </div>
      <div className="athena_image" >
          <img src={asset2} alt="display iamge of prototype" />
      </div>
    </div>
    </div>
  );
};


export default Header3;