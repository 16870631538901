import React from "react";
import asset5 from "../../../assets/athena_asset5.png";
import asset6 from "../../../assets/athena_asset6.png";
import asset7 from "../../../assets/rapid_asset7.png";
import asset8 from "../../../assets/rapid_asset8.png";
import asset9 from "../../../assets/rapid_asset9.png";
import "./frameworks3.css";

const Frameworks3 = () => {
    return (
        
    <div className="section__padding container">
        <h1>FRAMEWORKS</h1>

    <div className="athena_frameworks section__padding2">
        <h2>Elements</h2>
        <div>
        <div className="flex_container">
                <p>Form</p>
                <div className="flex_list">
                    <ul>
                        <l1>Smartphone </l1> < br/>
                        <l1>Apple Watch </l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>User Input</p>
                <div className="flex_list">
                    <ul>
                        <l1>Touch (keypads) </l1> < br/>
                        <l1>Touch (icons) </l1>< br/>
                        <l1>Multiscreen Swipe </l1> < br/>
                        <l1>Scanning (camera) </l1>< br/>
                        <l1>Voice </l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Response </p>
                <div className="flex_list">
                    <ul>
                        <l1>Touch (keypads) </l1> < br/>
                        <l1>Touch (icons) </l1>< br/>
                        <l1>Multiscreen Swipe </l1> < br/>
                        <l1>Scanning (camera) </l1>< br/>
                        <l1>Voice </l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Data Elements</p>
                <div className="flex_list">
                    <ul>
                        <l1>Smartphone </l1> < br/>
                        <l1>Account </l1>< br/>
                        <l1>GPS </l1>< br/>
                        <l1>Financial account</l1>< br/>
                        <l1>SMS </l1>< br/>
                        <l1>Mapping </l1>< br/>
                        <l1>Routing</l1>< br/>
                        <l1>Account Settings</l1>< br/>
                        <l1>Direct Scan Payment</l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Functional </p>
                <div className="flex_list">
                    <ul>
                        <l1>Audio notification </l1>< br/>
                        <l1>Audio control</l1>< br/>
                        <l1>Touch control </l1>< br/>
                        <l1>Keyboard control</l1>< br/>
                        <l1>Connection to LAN Server / Transit / Wifi </l1>< br/>
                        <l1>Cross platform handshake</l1>< br/>
                        <l1>Tethering </l1>< br/>
                        <l1>Automatic scan payment</l1>< br/>
                        <l1>Health and safey notifications </l1>< br/>
                    </ul>
                </div>
            </div>
        </div>
        

    </div>
    <div className="athena_frameworks section__padding2">
        <h2>Elements</h2>
        <div>
        <div className="flex_container">
                <p>Form</p>
                <div className="flex_list">
                    <ul>
                        <l1>Smartphone </l1> < br/>
                        <l1>Apple Watch </l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>User Input</p>
                <div className="flex_list">
                    <ul>
                        <l1>Touch (keypads) </l1> < br/>
                        <l1>Touch (icons) </l1>< br/>
                        <l1>Multiscreen Swipe </l1> < br/>
                        <l1>Scanning (camera) </l1>< br/>
                        <l1>Voice </l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Response </p>
                <div className="flex_list">
                    <ul>
                        <l1>Touch (keypads) </l1> < br/>
                        <l1>Touch (icons) </l1>< br/>
                        <l1>Multiscreen Swipe </l1> < br/>
                        <l1>Scanning (camera) </l1>< br/>
                        <l1>Voice </l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Data Elements</p>
                <div className="flex_list">
                    <ul>
                        <l1>Smartphone </l1> < br/>
                        <l1>Account </l1>< br/>
                        <l1>GPS </l1>< br/>
                        <l1>Financial account</l1>< br/>
                        <l1>SMS </l1>< br/>
                        <l1>Mapping </l1>< br/>
                        <l1>Routing</l1>< br/>
                        <l1>Account Settings</l1>< br/>
                        <l1>Direct Scan Payment</l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Functional </p>
                <div className="flex_list">
                    <ul>
                        <l1>Audio notification </l1>< br/>
                        <l1>Audio control</l1>< br/>
                        <l1>Touch control </l1>< br/>
                        <l1>Keyboard control</l1>< br/>
                        <l1>Connection to LAN Server / Transit / Wifi </l1>< br/>
                        <l1>Cross-platform handshake</l1>< br/>
                        <l1>Tethering </l1>< br/>
                        <l1>Automatic scan payment</l1>< br/>
                        <l1>Health and safety notifications </l1>< br/>
                    </ul>
                </div>
            </div>
        </div>
        

    </div>
    <div className="athena_frameworks section__padding2">
        <h2>Requirements</h2>
        <div>
            <div className="flex_container">
                <p>Contactless</p>
                <div className="flex_list">
                    <ul>
                        <l1>for general use when internet access is functioning </l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Review</p>
                <div className="flex_list ">
                    <ul>
                        <l1>User is able to use their smartphone as a contactless payment method through scanning when they enter transit.  </l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Audio Prompt </p>
                <div className="flex_list ">
                    <ul>
                    <l1>User can review data in the future and make adjustments when needed according to transit options and health and safety measures. </l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Tethering</p>
                <div className="flex_list">
                    <ul>
                        <l1>User is given data from the transit authority about transit wait times and route information to their smartphone.  </l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Voice </p>
                <div className="flex_list">
                    <ul>
                        <l1>User is able to communicate with application by voice activation to minimize physical contact. </l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Food/Bev</p>
                <div className="flex_list ">
                    <ul>
                        <l1>User can order and pay for food and beverages from the application so it’s ready for pickup when they arrive at the station. </l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Updates</p>
                <div className="flex_list ">
                    <ul>
                    <l1>User is able to track their incoming transit options and cross-reference with their own location</l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>COVID</p>
                <div className="flex_list">
                    <ul>
                        <l1>Health and safety notifications are given to users regarding COVID protocols </l1>< br/>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>User Capacity</p>
                <div className="flex_list">
                    <ul>
                        <l1>User capacity is given to users on buses/subways and trains </l1>< br/>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div className="athena_frameworks section__padding2">
        <h2>Hierarchy</h2>
        <div className="rapid_image_requirements">
            <img src={asset8} alt="display iamge of prototype" />
        </div>
    </div>
      


    <div className="padding_container">
       <h2>Interaction Framework</h2>
       <p className="extra_padding">Requirements into Features</p>
      
        <ol className="extra_length">
          <li className="extra_length">Live Map Feature (Background)</li>
          <li className="extra_length">Updated GPS Routing</li>
          <li className="extra_length">Side Nav (Close menu)</li>
          <li className="extra_length">Payment Popup / Scan</li>
          <li className="extra_length">Handshake Feature</li>
          <li className="extra_length">Swipe Background Screen Gesture </li>
        </ol>

        <div className="rapid_image_requirements">
            <img src={asset7} alt="display iamge of prototype" />
        </div>

    </div>
    <div className="padding_container">
       <h2>Design Function Elements Prototype</h2>
       <p className="extra_padding">Requirements into Features</p>
      
        <ol className="extra_length">
          <li className="extra_length">Live Map Feature (Background)</li>
          <li className="extra_length">Updated GPS Routing</li>
          <li className="extra_length">Side Nav (Close menu)</li>
          <li className="extra_length">Payment Popup / Scan</li>
          <li className="extra_length">Handshake Feature</li>
          <li className="extra_length">Swipe Background Screen Gesture </li>
        </ol>

        <div className="rapid_image_requirements">
            <img src={asset9} alt="display iamge of prototype" />
        </div>

    </div>
    </div>
   
    );
  };
  
  export default Frameworks3;


