
import React from "react";
import { About } from '../containers/about';
import { Athena_nav } from '../components';

const Aboutme =()=>{
    return (
        <div className="app">
            <Athena_nav />
            <About />
    </div>

    )
}

export default Aboutme;