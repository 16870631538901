import React from "react";
import asset4 from "../../../assets/coc_asset4.png";

import "./frameworks.css";

const Scenarios = () => {
    return (
        
      <div className="container section__padding">
    
            <h1>FRAMEWORKS</h1>
  
        <div className="coc_image_frameworks">
            <img src={asset4} alt="display iamge of prototype" />
        </div>

        <div className="section__padding">
     
            <div className="framework_flex_container">
                <p>Technical Requirements</p>
    
                    <ul>
                        <li>Reads Single Polymorphism Markers (SNPs) on user</li>
                        <li>Reads electro-chemical signals / neurotransmitters</li>
                        <li>Reads blood hormonal ranges </li>
                        <li>Cross references SNPs with user database (local/cloud) </li>
                        <li>Blood readings are updated automatically </li>
                        <li>Readings turn to functional/accessible data  </li>
                    </ul>
    
            </div>

            <div className="framework_flex_container">
            <p>Functional Requirements</p>
              
              <ul>
                  <li>Automatic scan feature for SNPs</li>
                  <li>Touch-based scan feature  for SNPs</li>
                  <li>Voice-activated scan feature  for SNPs</li>
                  <li>Cross references SNPs with user database (local/cloud) </li>
                  <li>Blood readings are updated automatically </li>
                  <li>Readings turn to functional/accessible data  </li>
              </ul>
            
            </div>

            

            <div className="framework_flex_container">
            <p>Data</p>
               
                    <ul>
                        <l1>SNP / Hormonal / Electro-Chemical Markers</l1><br />
                        <l1>Medical history</l1><br />
                        <l1>Recipe </l1><br />
                        <l1>Encryption data needs  </l1><br />
                        <l1>User health monitor  </l1><br />
                    </ul>
               
            </div>

            
            <div className="framework_flex_container">
                <p>Features</p>
                    <ul>
                        <l1>Monitors genetic biomarkers in the genome (SNPs)</l1><br />
                        <l1>Monitors hormones released in users body</l1><br />
                        <l1>Accounts for data values of daily required protein</l1><br />
                        <l1>Accounts for data values of daily required fats</l1><br />
                        <l1>Accounts for data values of daily required carbohydrates</l1><br />
                        <l1>Accounts for daily values of amino acids, vitamins and minerals </l1><br />
                        <l1>Automatic notifications of low health markers  </l1><br />
                        <l1>Data accounts for genetic predisposition  </l1><br />
                        <l1>Dignostisitc report for early-stage disease detection  </l1><br />
                        <l1>Adjustable health goals for users  </l1><br />
                        <l1>Tethered to individuals  </l1><br />
                    </ul>
                </div>
          
      
    </div>


      </div>
    

    
    );
  };
  
  export default Scenarios;



