import React from "react";
import asset3 from "../../../assets/coc_asset3.png";

import "./scenarios.css";

const Scenarios = () => {
    return (
   
        <div className="container section__padding2">
          <h1>SCENARIOS</h1>
          
        <div className="coc_image_scenarios">
            <img src={asset3} alt="display iamge of prototype" />
        </div>

        <div className="coc_scenarios padding_bottom">

                <div>
                    <div className="flex_con">
                        <p className="paragraph_padding_scenarios">Key Path 1</p>
                        <div className="flex_list2">
                            <ul>
                                <li>Kara wakes up from a stressful night of not sleeping as her autoimmune condition had been giving her terrible muscle pain. However, Kara is tougher than nails and shrugs it off as she is used too. She opens her App which automatically scans her body for optimized daily nutritional needs.</li>
                            </ul>
                    </div>
                    </div>
                    <div className="flex_con">
                    <p className="paragraph_padding_scenarios">Key Path 2</p>
                        <div className="flex_list2">
                            <ul>
                                <li>Kara goes to the kitchen and begins to meal prep for the day while staying logged onto the App, which creates food recommendations according to her genetic markers and conditions.</li>
                            </ul>
                    </div>
                    </div>
                    <div className="flex_con">
                    <p className="paragraph_padding_scenarios">Key Path 3</p>
                        <div className="flex_list2">
                            <ul>
                                <li>Kara then logs her MS medications into the platform which helps time the medications with her nutrition for further optimal health benefits.</li>
                            </ul>
                    </div>
                    </div>
                </div>

                <div>
                    <div className="flex_con">
                    <p className="paragraph_padding_scenarios">Key Path 4</p>
                    <div className="flex_list2">
                            <ul>
                                <li>She goes to work feeling strong and well despite her long night. Work is busy and hectic, but she is able to make it through the day after some long troubleshooting hours that required intense focus. She was able to take some nootropics that helped manage the side effects from her corticosteroid use after a notification let her know her acetylcholine markers were low. </li>
                            </ul>
                    </div>
                    </div>
                    <div className="flex_con">
                    <p className="paragraph_padding_scenarios">Key Path 5</p>
                    <div className="flex_list2">
                            <ul>
                                <li>After work Kara returns home and logs on to the App to input data about her body and emotional state. She makes a dinner full of sulfur-rich vegetables and hot Tumeric tea while relaxing to some scifi after further App suggestions. </li>
                            </ul>
                    </div>
                    </div>
                    <div className="flex_con">
                    <p className="paragraph_padding_scenarios">Key Path 6</p>
                    <div className="flex_list2">
                            <ul>
                                <li>Kara signs into the App one last time before bed to go over the diagnostics for the day. </li>
                            </ul>
                    </div>
                </div>
                </div>
    </div>
    </div>
    
    );
  };
  
  export default Scenarios;



