
import React from "react";
import { Cv } from '../containers/cv';
import { Athena_nav } from '../components';

const cv =()=>{
    return (
        <div className="app">
            <Athena_nav />
            <Cv />
    </div>

    )
}

export default cv;