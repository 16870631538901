import React from "react";
// import { Header, Method, Modeling2, Requirements2, Frameworks2 } from '../containers/athena';
import { Athena_nav } from '../components';
import { Landing } from '../containers/landing';


const Landing2 =()=> {
    return (
        <div className="app">
        <Landing />
    </div>

    )
}

export default Landing2;