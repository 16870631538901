
import React from "react";
import { Header3, Method3, Modeling3, Requirements3, Frameworks3 } from '../containers/rapid';
import logo from '../../src/assets/logo.svg';

const Rapid =()=>{
    return (
        <div className="app3">

<div className="navbar nav_padding container">
            <div className="navbar-links">
       
            <div className="navbar-links_container ">
            <p><a href="#rapid_method3">Methodology</a></p>
            <p><a href="#rapid_modeling3">Modeling</a></p>
            <p><a href="#rapid_requirements3">Requirements</a></p>
            <p><a href="#rapid_frameworks3">Scenarios</a></p>
            <p><a href="#rapid_frameworks3">Framework</a></p>
            <p><a href="#rapid_frameworks3">Prototype</a></p>
            </div>
      </div>

      <div className="navbar-links_logo">
        <a href="../../"><img src={logo} /></a>
      </div>

    </div>
        <div id="rapid_header3">
            <Header3 />
        </div>
        <div id="rapid_method3">
            <Method3 />
        </div>
        <div id="rapid_modeling3">
            <Modeling3 />
        </div>
        <div id="rapid_requirements3">
            <Requirements3 />
        </div>
        <div id="rapid_frameworks3">
            <Frameworks3 />  
        </div>
    </div>

    )
}

export default Rapid;