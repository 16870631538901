import React from "react";
import asset3 from "../../../assets/rapid_asset3.png";
import asset2 from "../../../assets/athena_asset2.png";
import asset4 from "../../../assets/rapid_asset4.png";
import './modeling3.css'

const Modeling3 = () => {
  return (
    <div className="container section__padding">
    <div className="athena_modeling">

      <div className="athena_header_section">
        <h1>MODELING</h1>
        <h3>Persona Hypothesis</h3>
        <h4>Potential Roles</h4>
        <p class="paragraph_padding_bottom">I grouped my interview subjects by job title which is directly related to transit usage. My subjects are all young adults who use transit and for work or school.</p>
        <h4>Needs and Behaviors</h4>

        <p class="paragraph_padding_bottom">User transit needs are more than 3 times per week, however, this behavior could be altered by environmental
        considerations. Some users could use transit once per week, while others could use it daily. Transit is used to get from
        point A - B. The actual transit route will vary significantly depending on social and economic considerations. </p>
        
        <p class="paragraph_padding_bottom">For example, a high-income user might be able to live on the transit line while a lower-income individual might have to live
        further from the city increasing their commute.</p>

        <p class="paragraph_padding_bottom">User can vary their usage by using transit for work and/or social activities. Use a scheduling app to help with time
        management and organization. However, this is an assumption that all users have a smartphone. Some lower-income
        users might not be able to afford a smartphone making this less accessible. Furthermore, older smartphones running
        older operating systems might not be able to use current applications made for Android and IOS. Transit users have
        to live in a metropolitan area that has a transit system, however, this system could vary in complexity and size.</p>

        <h4>Environment</h4>
        <p class="paragraph_padding_bottom">Behaviors could be altered by environmental considerations. Some users could use transit once per week, while others could use it daily. The actual transit route will vary significantly depending on social and economic considerations. For example, a high-income user might be able to live on the transit line while a lower-income individual might have to live further from the city increasing their commute.</p>
          <div className="method_container">
            <h3 className="extra_padding">Synthesize User Goals</h3>
      
            <ol className="extra_length">
              <li className="extra_length">Create an interactive environment for students that enhances academics alongside interpersonal skills</li>
              <li className="extra_length">Have accessible technical support available at all times </li>
              <li className="extra_length">Have proper accountability coordination between school, parent and student</li>
              <li className="extra_length">Have proper training for teachers and parent</li>
              <li className="extra_length">Have students use their bodies more often and limit screen time when possible</li>
            </ol>
          
      </div>
      
      <div className="method_wrapper">
        <div>
          <h2>Persona 1 </h2>
          <p>Tory Foster | Student </p>
            <p className="persona_text">Quote</p>
            <p>Push yourself always, because no one else is going to do it for you.</p>
            <p className="persona_text">Bio</p>
   
            <p class="padding_bottom"> Tory is a web-developer working for a startup company. She lives downtown in a new condo with her rescue pit bull. She worked her way through college as a waitress and had a very active social life. She struggled to know what she wanted to do with her career for several years but decided to go into freelance web development. After freelancing for several years, she was hired by an up-and-coming commercial startup.</p>
       
            <p class="padding_bottom">  Tory is extremely passionate about her career to the point of putting her social life on the sidelines. She is a borderline workaholic and spends a lot of her time at work. She depends on accessible transit because she wants to save for a larger house for her dog. She also wants to move to a safer area because there is a lot of poverty in her community. </p>
            
            <p class="padding_bottom"> She is very comfortable with technology and is capable of learning Andriod and IOs. She is also a tech geek and likes to have the latest gadget on her at all times. She is glued to her smartphone and starts work before she arrives at the office. She has to remember to take breaks and enjoy the simpler things in life.</p>
        
            <p className="persona_text">Goals</p>
            <ul>
                <li>Rent a larger home</li>
                <li>Make $60 000 / year</li>
                <li>Get a raise</li>
                <li>Stay safe and healthy </li>
      
            </ul>
            <p className="persona_text">Concerns</p>
            <ul>
                <li> Works hard and forgets to have a social life </li>
                <li> Concerned for her health and welfare</li>
                <li> Not making enough money</li>
                <li> Getting home late at night from work</li>
            </ul>
          </div>
        <div className="athena_image_persona">
            <img src={asset3} alt="display image of prototype" />
        </div>
      </div>
    
    <div className="method_wrapper">
  
      <div className="athena_image_persona2">
            <img src={asset4} alt="display image of prototype" />
        </div>
        
        <div>
        <h2>Persona 2 </h2>
        <p>Leobein Conoy | Warehouse Manager </p>
            <p className="persona_text">Quote</p>

            <p>Your children are likely to live up to what you believe and
see in them. </p>
            <p className="persona_text">Bio</p>
   
            <p class="padding_bottom">Leobein is a working-class family man who is a manager at a warehouse. He worked his way up from a low-income family and gained a diploma in business management. After school, he worked hard to pay off his loans and developed a strong business ethos. He had a son with his girlfriend, but they divorced however maintain strong relations.</p>
         
            <p class="padding_bottom">He is a strong family man and would do anything for his son. He wants to set a good example and maintain financial independence. His free time is occupied with extracurricular activities on the weekends while his son is at school. As much as he is a strong worker, his family is the most important part of his life.</p>
          

            <p class="padding_bottom">To save money he lives outside the city but his place of employment is downtown, so he has a long commute. He doesn’t like to waste his time as any time away from his family is not well spent therefore he is strict with his time. His strong work ethic and family influence his life and digital platforms, as he enjoys applications and technology that make his time more productive. Technology is means to an end to increasing time with the most important aspects of his life.</p>
          
           
            <p className="persona_text">Goals</p>
            <ul>
                <li>Save money </li>
                <li>Be a good father</li>
                <li>Save for retirement and family  </li>
                <li>Stay healthy for his child </li>
                <li>Become a community leader</li>
      
            </ul>
            <p className="persona_text">Concerns</p>
            <ul>
                <li> Not enough time spent with family</li>
                <li> Child’s safety and welfare</li>
                <li> Save enough money for his family</li>
                <li> Losing job to automaton</li>
            </ul>
        </div>
    </div>
    </div>
    </div>
    </div>

  );
};

export default Modeling3;