import React from "react";
import "./requirements.css";

const Requirements = () => {
    return (
    <div className="container">
    <div className="coc_requirements_header section__padding">
      <h1>REQUIREMENTS</h1>
    </div>


    <div className="coc_requirements section__padding2">
        <h2 className="h2_column_width">Problem+</h2>
        <div>
            
            <div className="flex_container">
                <p> Change</p>
                <div className="flex_list">
                    <ul>
                        <l1>Consumers are not given proper information about how food and nutritional supplementation can affect their genetic genome creating pain points in their health and wellness. </l1>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>User Issues</p>
                <div className="flex_list">
                    <ul>
                        <l1>People are unaware that different food and supplements can affect their health individual health according to their genetic markers in a process called nutrigenetics. </l1>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Impact</p>
                <div className="flex_list">
                    <ul>
                        <l1>Disease induced by foodstuffs is a massive strain on Canadian society in which individuals know very little about. Furthermore, recent developments in science and technology are not being utilized to help people live longer and heather lives by altering their diet to incorporate their genetic markers. </l1>
                    </ul>
                </div>
            </div>
            </div>
    </div>

    <div className="coc_requirements section__padding2">
    <h2 className="h2_column_width">Vision+</h2>
        <div>
            <div className="flex_container">
                <p>Change</p>
                <div className="flex_list">
                    <ul>
                        <l1>Consumers are given accessible information about their health and wellness according to their genetic markers that will be enhanced by their foodstuff.</l1>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>User Gain</p>
                <div className="flex_list">
                    <ul>
                        <l1>Users will gain a greater chance of health and longevity in their lives by utilizing their genetics with an accessible dynamic app that helps them manage their food and supplement requirements. </l1>
                    </ul>
                </div>
            </div>
            <div className="flex_container">
                <p>Benefits</p>
                <div className="flex_list">
                    <ul>
                        <l1>The benefits range from the individual to the macro in which greater health and wellness through individual accountability will enhance all aspects of the uses life through optimal health and wellness. </l1>
                    </ul>
                </div>
            </div>
            </div>
        
    </div>
    </div>
    );
  };
  
  export default Requirements;




